import Menubar from "./components/Menubar";

class AccessibilityBundle {
    static init() {
        AccessibilityBundle.initAccessibilityBar();
        AccessibilityBundle.initMotionControl();
        AccessibilityBundle.initKeyboardNavigation();
        AccessibilityBundle.initKeyboardNavbarNavigation();
    }

    static initAccessibilityBar() {
        const settings = AccessibilityBundle.getConfig().accessibility_bar;

        AccessibilityBundle.applySettings(settings);
    }

    static initMotionControl() {
        let config = AccessibilityBundle.getConfig().accessibility_bar.motion_toggle;

        let videos = document.querySelectorAll('video');
        if (videos.length > 0) {
            videos.forEach((video) => {

                if (video.hasAttribute('autoplay')) {
                    video.dataset.initialState = 'playing';
                }

                video.addEventListener('ended', () => {
                    video.dataset.initialState = 'ended';
                });

                if (video.hasAttribute('controls')) {
                    video.dataset.initialControls = 'true';
                } else {
                    video.dataset.initialControls = 'false';
                }

                AccessibilityBundle.toggleVideoMotion(video);
            });
        }

        AccessibilityBundle.toggleTnsLoop();

        let toggleButtons = document.querySelectorAll('.' + config.toggleClass);

        if (toggleButtons.length === 0) {
            return;
        }

        toggleButtons.forEach((button) => {
            button.addEventListener('click', () => {
                if (videos.length > 0) {
                    videos.forEach((video) => {
                        AccessibilityBundle.toggleVideoMotion(video);
                    });
                }

                AccessibilityBundle.toggleTnsLoop();
            });
        });
    }

    static toggleVideoMotion(video) {

        if (document.body.classList.contains('motion-stopped')) {
            video.pause();
            video.setAttribute('controls', '');
        } else {
            if (video.dataset.initialState === 'playing') {
                video.play();
            }
            if (video.dataset.initialControls === 'false') {
                video.removeAttribute('controls');
            } else {
                video.setAttribute('controls', '');
            }
        }
    }

    static toggleTnsLoop() {
        if (window.TinySliderBundle) {
            let slides = window.TinySliderBundle.getSliders();
            if (slides.length > 0) {
                slides.forEach(slide => {
                    if (document.body.classList.contains('motion-stopped')) {
                        slide.pause();
                    } else {
                        slide.play();
                    }
                })
            }
        }
    }

    static applySettings(settings) {
        for (const [key, option] of Object.entries(settings)) {

            option.value = option.defaultValue;

            let toggles = document.querySelectorAll('.' + option.toggleClass);

            if (toggles.length < 1) {
                continue;
            }

            toggles.forEach((toggle) => {
                AccessibilityBundle.updateToggle(toggle, option);

                toggle.addEventListener('click', (event) => {
                    event.preventDefault();
                    // update option value and localStorage
                    option.value = !option.value;
                    localStorage.setItem(option.localStorage, JSON.stringify({name: key, value: option.value}));

                    const e = new CustomEvent('accessibilityBarOnToggle', {detail: {name: key, value: option.value}});
                    document.dispatchEvent(e);

                    AccessibilityBundle.updateToggle(toggle, option);
                });
            });
        }
    }

    // update aria properties of pressed/clicked toggle/button
    static updateToggle(toggle, option) {
        let statusText;
        let local = JSON.parse(localStorage.getItem(option.localStorage));
        const useBootstrap = AccessibilityBundle.getConfig().accessibility_bar.contrast_toggle.useBootstrap;

        if (local === null) {
            local = {};
        }

        option = Object.assign(option, local);

        toggle.setAttribute('aria-checked', option.value);

        if (option.value) {

            if (useBootstrap) {
                if (option.bodyClass === 'high-contrast') {
                    document.body.setAttribute('data-bs-theme', 'accessibility');
                }
            }
            document.body.classList.add(option.bodyClass);
            statusText = toggle.getAttribute('data-active-text');
        } else {
            if (useBootstrap) {
                if (option.bodyClass === 'high-contrast') {
                    document.body.setAttribute('data-bs-theme', 'light');
                }
            }
            document.body.classList.remove(option.bodyClass);
            statusText = toggle.getAttribute('data-inactive-text');
        }

        if (statusText !== null) {
            toggle.setAttribute('title', statusText);
            toggle.setAttribute('aria-label', statusText);
        }
    }

    static initKeyboardNavigation() {
        let next = null,
            config = AccessibilityBundle.getConfig(),
            cssClass = config.keyboard_navigation.css_class,
            cookiebarLinkSelector = config.keyboard_navigation.cookiebar_link_selector;

        // set focus on cookiebar link as first element to be tabbed to before regular content
        if (cookiebarLinkSelector) {
            let cookiebarLink = document.querySelector(cookiebarLinkSelector);

            if (cookiebarLink !== null) {
                cookiebarLink.tabIndex = 0;
                next = cookiebarLink;
            }
        }

        // tab focus and using-keys css class
        document.body.addEventListener('keyup', function(e) {
            if (e.key === 'Tab') {
                if (['input', 'textarea', 'select'].indexOf(e.target.tagName.toLowerCase()) < 0) {
                    document.body.classList.add(cssClass);
                }

                if (next !== null) {
                    if (!document.body.classList.contains(cssClass)) {
                        document.body.classList.add(cssClass);
                    }
                    next.focus();
                    next = null;
                }

                if (e.target === document.querySelector('button[data-cookiebar-accept]')) {
                    next = document.querySelector('[title="Startseite"]');
                }
            }

            if (e.shiftkey && e.key === 'Tab') {
                if (next !== null) {
                    next.focus();
                    next = null;
                }
            }

        });

        document.body.addEventListener('mousedown', function() {
            document.body.classList.remove(cssClass);
        });
    }

    static initKeyboardNavbarNavigation() {
        let config = AccessibilityBundle.getConfig();
        let navigationNodes = document.querySelectorAll(config.keyboard_navigation.sub_menu.wrapper_main_nav_link);

        navigationNodes && navigationNodes.forEach((navi) => {
            let menu = new Menubar(navi, config);
            menu.init();
        })

        let navModuleWrappers = document.querySelectorAll(config.keyboard_navigation.sub_menu.module_wrapper);

        if (navModuleWrappers) {

            navModuleWrappers.forEach(mod => {

                const observerConfig = {attributes: true, childList: true, subtree: true};

                const callback = (mutationsList, observer) => {
                    for (const mutation of mutationsList) {
                        if (mutation.type === 'attributes' && mutation.target.classList.contains(config.keyboard_navigation.sub_menu.nav_link_selector.replace('.', ''))) {

                            let isOpen = false;
                            let navLinks = mod.querySelectorAll(config.keyboard_navigation.sub_menu.nav_link_selector)

                            navLinks.forEach(link => {
                                if (link.classList.contains(config.keyboard_navigation.sub_menu.nav_link_open_class)) {
                                    isOpen = true;
                                }
                            })

                            if (isOpen) {
                                mod.classList.add(config.keyboard_navigation.sub_menu.nav_link_open_class);
                            } else {
                                mod.classList.remove(config.keyboard_navigation.sub_menu.nav_link_open_class);
                            }

                        }
                    }
                }

                const navModuleObserver = new MutationObserver(callback);

                navModuleObserver.observe(mod, observerConfig);
            })
        }


    }

    static getConfig() {
        return JSON.parse(document.querySelector('[data-accessibility-config]').getAttribute('data-accessibility-config'));
    }
}

export {AccessibilityBundle};
