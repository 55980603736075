import Menubar from "./Menubar";

const SubmenuAlignment = {
    horizontal: 'horizontal',
    vertical: 'vertical'
}

class MenuItem {
    constructor(domNode, menuObj, isMainMenuItem = true) {
        this.menu = menuObj;
        this.domNode = domNode;
        this.subMenu = false;
        this.config = menuObj.config;
        this.isMainMenuItem = isMainMenuItem;

        this.keyCode = Object.freeze({
            'TAB': 9,
            'RETURN': 13,
            'ESC': 27,
            'SPACE': 32,
            'PAGEUP': 33,
            'PAGEDOWN': 34,
            'END': 35,
            'HOME': 36,
            'LEFT': 37,
            'UP': 38,
            'RIGHT': 39,
            'DOWN': 40
        });
    }

    init() {
        // this.domNode.tabIndex = -1;

        this.domNode.addEventListener('keydown', this.handleKeydown.bind(this));

        // Initialize sub menus

        var nextElement = this.domNode.nextElementSibling;
        if (nextElement && nextElement.tagName === 'UL') {
            this.subMenu = new Menubar(nextElement, this.config, this, false);
            this.subMenu.init();
        }
    }

    handleKeydown (event) {
        let flag = false;

        switch (event.keyCode) {
            case this.keyCode.RETURN:
                if(!this.subMenu) {
                    break;
                }
            case this.keyCode.SPACE:
            case this.keyCode.DOWN:
                if (this.menu.isMainMenu || this.menu.menuAlignment === SubmenuAlignment.horizontal) {
                    if (this.subMenu) {
                        this.subMenu.open();
                        this.subMenu.setFocusToFirstItem();
                    }
                } else {
                    this.menu.setFocusToNextItem(this);
                }
                flag = true;
                break;

            case this.keyCode.LEFT:
                if(this.menu.isMainMenu || this.menu.menuAlignment === SubmenuAlignment.horizontal) {
                    this.menu.setFocusToPreviousItem(this);
                } else {
                    this.menu.setFocusToController('prev');
                }
                flag = true;
                break;

            case this.keyCode.RIGHT:
                if (this.menu.isMainMenu || this.menu.menuAlignment === SubmenuAlignment.horizontal) {
                    this.menu.setFocusToNextItem(this);
                } else {
                    if (this.subMenu) {
                        this.subMenu.open();
                        this.subMenu.setFocusToFirstItem();
                    } else {
                        this.menu.setFocusToController('next');
                    }
                }
                flag = true;

                break;

            case this.keyCode.UP:
                if (this.menu.isMainMenu || this.menu.menuAlignment === SubmenuAlignment.horizontal) {
                    this.menu.setFocusToController();
                    this.menu.close();
                } else {
                    this.menu.setFocusToPreviousItem(this);
                }
                flag = true;
                break;

            case this.keyCode.HOME:
            case this.keyCode.PAGEUP:
                this.menu.setFocusToFirstItem();
                flag = true;
                break;

            case this.keyCode.END:
            case this.keyCode.PAGEDOWN:
                this.menu.setFocusToLastItem();
                flag = true;
                break;

            // case this.keyCode.TAB:
            //     this.menu.closeAll();
            //     break;

            case this.keyCode.ESC:
                this.menu.closeAll();
                break;

            default:
                break;
        }

        if (flag) {
            event.stopPropagation();
            event.preventDefault();
        }
    };
}

export default MenuItem;
