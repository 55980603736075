import MenuItem from "./MenuItem";

class Menubar {
    constructor(domNode, config, controllerObj = null, isMainMenu = true) {
        this.domNode = domNode;
        this.controller = controllerObj;
        this.config = config;

        this.isMainMenu = isMainMenu;
        this.menuAlignment = config.keyboard_navigation.sub_menu.alignment;

        this.menubarItems = []; // See Menubar init method

        this.firstItem = null; // See Menubar init method
        this.lastItem = null; // See Menubar init method

    }

    init() {
        let numItems;

        // Traverse the element children of menubarNode: configure each with
        // menuitem role behavior and store reference in menuitems array.
        let elem = this.domNode.firstElementChild;
        while (elem) {
            let menuElement = elem.firstElementChild;

            if (elem && menuElement && menuElement.tagName === 'A') {
                this.addToMenuItems(menuElement)

                // In case submenu contains multiple ul
            } else if (elem && menuElement && menuElement.tagName === 'UL') {
                while(menuElement) {
                    let listElements = menuElement.childNodes;
                    listElements.forEach(el => {
                        if(el.firstElementChild && el.firstElementChild.tagName === 'A') {
                            this.addToMenuItems(el.firstElementChild);
                        }
                    })
                    menuElement = menuElement.nextElementSibling;
                }
            }


            elem = elem.nextElementSibling;
        }

        // Use populated menuitems array to initialize firstItem and lastItem.
        numItems = this.menubarItems.length;
        if (numItems > 0) {
            this.firstItem = this.menubarItems[ 0 ];
            this.lastItem = this.menubarItems[ numItems - 1 ];
            this.firstItem.domNode.tabIndex = 0;
        }
    }

    addToMenuItems(linkNode) {
        if(linkNode.tagName === 'A' ) {
            let menubarItem = new MenuItem(linkNode, this, this.isMainMenu);
            menubarItem.init();
            this.menubarItems.push(menubarItem);
        }
    }

    setFocusToItem (newItem) {
        newItem.domNode.focus();
        // newItem.domNode.tabIndex = 0;
    };

    setFocusToNextItem (currentItem) {
        let index, newItem;

        if (currentItem === this.lastItem) {
            newItem = this.firstItem;
        }
        else {
            index = this.menubarItems.indexOf(currentItem);
            newItem = this.menubarItems[ index + 1 ];
        }

        // currentItem.domNode.tabIndex = -1;
        this.setFocusToItem(newItem);

    };

    setFocusToPreviousItem (currentItem) {
        var index, newItem;

        if (currentItem === this.firstItem) {
            newItem = this.lastItem;
        }
        else {
            index = this.menubarItems.indexOf(currentItem);
            newItem = this.menubarItems[ index - 1 ];
        }

        // currentItem.domNode.tabIndex = -1;
        this.setFocusToItem(newItem);

    };

    setFocusToFirstItem () {
        this.firstItem.domNode.focus();
    };

    setFocusToLastItem () {
        this.lastItem.domNode.focus();
    };

    setFocusToController (command = '') {
        if(this.controller) {
            if (command === '') {
                this.controller.domNode.focus();
                return;
            }

            if (this.controller.isMainMenuItem) {
                if (command === 'prev') {
                    this.controller.menu.setFocusToPreviousItem(this.controller);
                    this.close();
                }
                else if (command === 'next') {
                    this.controller.menu.setFocusToNextItem(this.controller);
                    this.close();
                }
            } else {
                if (command === 'prev') {
                    this.setFocusToController();
                    this.close()
                }
            }

        }
    };

    open() {
        this.controller.domNode.setAttribute('aria-expanded','true');
        this.controller.domNode.classList.add(this.config.keyboard_navigation.sub_menu.nav_link_submenu_open_class)
        this.domNode.classList.add(this.config.keyboard_navigation.sub_menu.nav_link_submenu_open_class)
    }

    close() {
        if(this.controller) {
            this.controller.domNode.setAttribute('aria-expanded','false');
            this.controller.domNode.classList.remove(this.config.keyboard_navigation.sub_menu.nav_link_submenu_open_class)
            this.domNode.classList.remove(this.config.keyboard_navigation.sub_menu.nav_link_submenu_open_class)
        }
    }

    closeAll() {
        // set all nav-link aria-expanded to false
        let navlinks = document.querySelectorAll(this.config.keyboard_navigation.sub_menu.nav_link_submenu_selector);
        navlinks && navlinks.forEach(item => {
            item.setAttribute('aria-expanded','false');
            item.classList.remove(this.config.keyboard_navigation.sub_menu.nav_link_submenu_open_class)
        })

        // close all submenu
        for (var i = 1; i <= this.config.keyboard_navigation.sub_menu.number_of_level; i++) {
            let menus = document.querySelectorAll('ul.level_'+i+'.'+this.config.keyboard_navigation.sub_menu.nav_link_submenu_open_class);
            menus && menus.forEach(item => {
                item.classList.remove(this.config.keyboard_navigation.sub_menu.nav_link_submenu_open_class);
            })
        }
    }

}

export default Menubar;
